<template>
  <div class="footer">
    <div id="footer" class="row justify-content-center">
      <div class="col-11 col-xl-9">
        <div class="row">
          <div class="col-12 col-md-2 col-xl-4 logo">
            <img :src="require('../assets/images/footer-logo.png')" alt="" />
          </div>
          <div class="col-12 col-md-10 col-xl-8">
            <div class="row">
              <div class="col-6 contacts-container">
                <div class="title">Контакти</div>
                <p>+380 67 232 78 81</p>
                <p>
                  <a class="mail" href="mailto:info@steam-informatica.in.ua"
                    >info@steam-informatica.in.ua</a
                  >
                </p>
                <a
                  href="http://facebook.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i class="fab fa-facebook"></i>
                </a>
              </div>
              <div class="col-6 contacts-container">
                <div class="title">Адреса</div>
                <p>м. Київ, вул. Тарасівська, 23/25</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.footer {
  padding: 100px 0;
  background: #602e70;
  position: relative;
}

.contacts-container {
  text-align: right;
  .title {
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    color: #fff;
    margin: 0 0 20px 0;
  }
  p {
    margin: 0 !important;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    color: #fff;
    letter-spacing: -0.015em;
  }
  a {
    color: #fff;
    font-size: 30px;
  }
}

.mail {
  margin: 0 !important;
  font-style: normal;
  font-weight: 400;
  font-size: 18px !important;
  text-decoration: none;
  line-height: 22px;
  color: #fff;
  letter-spacing: -0.015em;
}

@media (max-width: 768px) {
  .logo {
    text-align: center;
    margin-bottom: 30px;
  }
  .footer {
    padding: 50px 0;
  }
}

@media (max-width: 550px) {
  .contacts-container {
    text-align: center;
    .title {
      font-size: 12px;
      line-height: 16px;
    }
    p {
      font-size: 12px;
      line-height: 16px;
    }
    .mail {
      font-size: 12px !important;
      line-height: 16px;
    }
  }
}
</style>