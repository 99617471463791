<template>
  <div class="container-fluid">
    <div class="row justify-content-center">
      <div class="col-11 col-xl-9 content">
        <div class="joined-title">
          {{data.who_joined.title}}
        </div>
      </div>
      <div class="col-12 col-md-11 partners-desctop">
        <div class="joined-image">
          <img :src="require('../assets/images/partners-ultra.png')" alt="" />
        </div>
      </div>
      <div class="steps-slider">
        <div
          v-for="item in partners"
          :key="item.idx"
          v-show="currentSlide === item.idx"
          class="step-slide"
        >
          <div class="slide-number">
            <img :src="require(`../assets/images/${item.img}`)" alt="" />
          </div>
        </div>
        <div class="slider-control">
          <div @click="slideDecr" class="control-arrow">
            <i class="fas fa-chevron-left"></i>
          </div>
          <div class="slider-dots">
            <i
              v-for="item in partners"
              :key="item.idx"
              :class="{ active: currentSlide === item.idx }"
              class="fas fa-circle"
            ></i>
          </div>
          <div @click="slideIncr" class="control-arrow">
            <i class="fas fa-chevron-right"></i>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import locale from '../locale/locale.json'
export default {
  data() {
    return {
      currentSlide: 1,
      data: locale,
      partners: [
        {
          idx: 1,
          img: "partner-1.svg",
        },
        {
          idx: 2,
          img: "partner-2.svg",
        },
        {
          idx: 3,
          img: "partner-3.svg",
        },
        {
          idx: 4,
          img: "partner-4.svg",
        },
        {
          idx: 5,
          img: "partner-5.svg",
        },
        {
          idx: 6,
          img: "partner-6.svg",
        },
        {
          idx: 7,
          img: "partner-7.svg",
        },
      ],
    };
  },
  methods: {
    slideIncr() {
      if (this.currentSlide < this.partners.length) {
        this.currentSlide++;
      } else {
        this.currentSlide = 1;
      }
    },
    slideDecr() {
      if (this.currentSlide > 1) {
        this.currentSlide--;
      } else {
        this.currentSlide = this.partners.length;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.joined-title {
  font-style: normal;
  font-weight: 800;
  font-size: 64px;
  line-height: 78px;
  width: 70%;
}

.joined-image {
  img {
    width: 100%;
    margin: -200px 0 0 0;
  }
}

@media (max-width: 1200px) {
  .joined-title {
    font-style: normal;
    font-weight: 800;
    font-size: 56px;
    line-height: 58px;
    width: 100%;
  }
  .joined-image {
    img {
      margin: -100px 0 0 0;
    }
  }
  .content {
    margin: 80px 0 40px;
  }
}

@media (max-width: 700px) {
  .joined-title {
    font-style: normal;
    font-weight: 800;
    font-size: 26px;
    line-height: 38px;
    width: 100%;
    text-align: center;
  }
}

.steps-slider {
  display: none;
  margin: 0 0 50px;
  .slider-control {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    .control-arrow {
      border: 1px solid black;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 10px 14px;
    }
    .slider-dots {
      width: 40%;
      display: flex;
      justify-content: space-between;
      i {
        color: #e2e4e5;
        font-size: 12px;
        margin: 0 2px;
      }
      .active {
        color: #000 !important;
      }
    }
  }
  .step-slide {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-bottom: 10px;
    .slide-number {
      display: flex;
      justify-content: center;
      border: 1px solid #B9A3C9;
      border-radius: 50%;
      height: 220px;
      width: 220px;
      img {
        width: 60%;
      }
      .three-slide-img {
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #b9a3c9;
        padding: 10px 20px;
      }
    }
    .slide-text-title {
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 18px;
      padding: 10px 0 0;
      text-align: center;
    }
    .slide-text {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
      padding: 10px 0 0;
      text-align: center;
    }
  }
}

@media (max-width: 900px) {
  .steps-slider {
    display: block;
  }
  .partners-desctop {
    display: none;
  }
}
</style>