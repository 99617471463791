<template>
  <div class="container-fluid content">
    <div class="row justify-content-center">
      <div class="col-11 col-xl-9">
        <div class="title">{{ data.procces.title }}</div>
      </div>
    </div>
    <div class="row justify-content-center platforms d-none d-md-flex">
      <div class="col-11 col-xl-8 col-xxl-6">
        <div class="row justify-content-evenly align-items-center features">
          <div class="col text">
            {{ data.procces.item_1 }}
          </div>
          <div class="col image">
            <img
              :src="require('../assets/images/study-platform-icon-1.png')"
              alt=""
            />
          </div>
        </div>
        <div class="row justify-content-evenly align-items-center features">
          <div class="col image">
            <img
              :src="require('../assets/images/study-platform-icon-2.png')"
              alt=""
            />
          </div>
          <div class="col text">
            {{ data.procces.item_2 }}
          </div>
        </div>
        <div class="row justify-content-evenly align-items-center features">
          <div class="col text">
            {{ data.procces.item_3 }}
          </div>
          <div class="col image">
            <img
              :src="require('../assets/images/study-platform-icon-3.png')"
              alt=""
            />
          </div>
        </div>
      </div>
      <div class="col-3 d-none d-xxl-block">
        <div class="ellipse">
          <img :src="require('../assets/images/procces-ellipse.png')" alt="" />
        </div>
      </div>
    </div>
    <div class="steps-slider">
      <div v-if="currentSlide === 1" class="step-slide">
        <div class="slide-number">
          <img
            :src="require('../assets/images/study-platform-icon-1.png')"
            alt=""
          />
        </div>
        <div class="slide-text">
          {{ data.procces.item_1 }}
        </div>
      </div>
      <div v-if="currentSlide === 2" class="step-slide">
        <div class="slide-number">
          <img
            :src="require('../assets/images/study-platform-icon-2.png')"
            alt=""
          />
        </div>
        <div class="slide-text">
          {{ data.procces.item_2 }}
        </div>
      </div>
      <div v-if="currentSlide === 3" class="step-slide">
        <div class="slide-number">
          <img
            :src="require('../assets/images/study-platform-icon-3.png')"
            alt=""
          />
        </div>
        <div class="slide-text">
          {{ data.procces.item_3 }}
        </div>
      </div>
      <div class="slider-control">
        <div @click="slideDecr" class="control-arrow">
          <i class="fas fa-chevron-left"></i>
        </div>
        <div class="slider-dots">
          <i :class="{ active: currentSlide === 1 }" class="fas fa-circle"></i>
          <i :class="{ active: currentSlide === 2 }" class="fas fa-circle"></i>
          <i :class="{ active: currentSlide === 3 }" class="fas fa-circle"></i>
        </div>
        <div @click="slideIncr" class="control-arrow">
          <i class="fas fa-chevron-right"></i>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import locale from "../locale/locale.json";
export default {
  data() {
    return {
      data: locale,
      currentSlide: 1,
    };
  },
  methods: {
    slideIncr() {
      if (this.currentSlide < 3) {
        this.currentSlide++;
      } else {
        this.currentSlide = 1;
      }
    },
    slideDecr() {
      if (this.currentSlide > 1) {
        this.currentSlide--;
      } else {
        this.currentSlide = 3;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@media (min-width: 1200px) {
  .content {
    padding: 180px 0;
  }
}

.title {
  width: 70%;
  font-style: normal;
  font-weight: 800;
  font-size: 64px;
  line-height: 78px;
}

@media (max-width: 1200px) {
  .title {
    width: 100%;
    text-align: center;
    margin: 80px 0 0;
    font-size: 56px;
    line-height: 68px;
  }
}

.ellipse {
  img {
    width: 100%;
    margin: 0 0 0 60%;
  }
}

.platforms {
  padding: 160px 0 0;
}

.features {
  .text {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
  }
}

@media (max-width: 1000px) {
  .features {
    .text {
      font-size: 16px;
      line-height: 20px;
    }
  }
}

.steps-slider {
  display: none;
  margin: 40px 0;
  .slider-control {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin: 40px 0;
    .control-arrow {
      border: 1px solid black;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 10px 14px;
    }
    .slider-dots {
      width: 20%;
      display: flex;
      justify-content: space-between;
      i {
        color: #e2e4e5;
        font-size: 12px;
        margin: 0 2px;
      }
      .active {
        color: #000 !important;
      }
    }
  }
  .step-slide {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 309px;
    .slide-number {
      display: flex;
      justify-content: center;
      width: 50%;
      img {
        width: 100%;
      }
      .three-slide-img {
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #b9a3c9;
        padding: 10px 20px;
      }
    }
    .slide-text {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
      padding: 30px 0 0;
      text-align: center;
    }
  }
}

@media (max-width: 768px) {
  .steps-slider {
    display: block;
  }
  .title {
    font-size: 26px;
    line-height: 28px;
  }
}
</style>