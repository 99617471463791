<template>
  <div class="container-fluid content">
    <div class="steps-slider">
      <div v-if="currentSlide === 1" class="step-slide">
        <div class="slide-number">
          <img :src="require('../assets/images/one.png')" alt="" />
        </div>
        <div v-html="data.steps.item_1" class="slide-text"></div>
      </div>
      <div v-if="currentSlide === 2" class="step-slide">
        <div class="slide-number">
          <img :src="require('../assets/images/two.png')" alt="" />
        </div>
        <div v-html="data.steps.item_2" class="slide-text"></div>
      </div>
      <div v-if="currentSlide === 3" class="step-slide">
        <div class="slide-number">
          <div class="three-slide-img">
            <img :src="require('../assets/images/three.png')" alt="" />
          </div>
        </div>
        <div v-html="data.steps.item_3" class="slide-text"></div>
      </div>
      <div class="slider-control">
        <div @click="slideDecr" class="control-arrow">
          <i class="fas fa-chevron-left"></i>
        </div>
        <div class="slider-dots">
          <i :class="{ active: currentSlide === 1 }" class="fas fa-circle"></i>
          <i :class="{ active: currentSlide === 2 }" class="fas fa-circle"></i>
          <i :class="{ active: currentSlide === 3 }" class="fas fa-circle"></i>
        </div>
        <div @click="slideIncr" class="control-arrow">
          <i class="fas fa-chevron-right"></i>
        </div>
      </div>
    </div>
    <div class="row justify-content-center d-none d-md-flex three-steps">
      <div class="col-xl-3 col-md-4 col-11 step">
        <img :src="require('../assets/images/one.png')" alt="" />
        <div v-html="data.steps.item_1" class="text"></div>
      </div>
      <div class="col-xl-3 col-md-4 col-11 step">
        <img :src="require('../assets/images/two.png')" alt="" />
        <div v-html="data.steps.item_2" class="text"></div>
      </div>
      <div class="col-xl-3 col-md-4 col-11 step">
        <div class="three-container">
          <img :src="require('../assets/images/three.png')" alt="" />
        </div>
        <div v-html="data.steps.item_3" class="text"></div>
      </div>
    </div>
    <div class="bubble-image d-none d-xl-block">
      <img :src="require('../assets/images/steps-ellipse.png')" alt="" />
    </div>
    <div class="row justify-content-center">
      <div class="col-xl-5 col-11 result">
        <div class="result-title">
          {{ data.steps.result.title }}
        </div>
        <div class="result-description">
          <ul>
            <li>
              <div class="result">
                {{ data.steps.result.item_1 }}
              </div>
            </li>
            <li>
              <div class="result">
                {{ data.steps.result.item_2 }}
              </div>
            </li>
            <li>
              <div class="result">{{ data.steps.result.item_3 }}</div>
            </li>
            <li>
              <div class="result">{{ data.steps.result.item_4 }}</div>
            </li>
            <li>
              <div class="result">{{ data.steps.result.item_5 }}</div>
            </li>
            <li>
              <div class="result">
                {{ data.steps.result.item_6 }}
              </div>
            </li>
            <div class="result-footer">
              {{ data.steps.result.subtitle }}
            </div>
          </ul>
        </div>
      </div>
      <div class="col-xl-4 d-none d-xl-block code-image">
        <img :src="require('../assets/images/code-image-2.png')" alt="" />
      </div>
    </div>
    <div class="drone-bubbles-mobile">
      <img :src="require('../assets/images/drone-bubbles-mobile.png')" alt="" />
    </div>
  </div>
</template>

<script>
import locale from "../locale/locale.json";
export default {
  data() {
    return {
      currentSlide: 1,
      data: locale,
    };
  },
  methods: {
    slideIncr() {
      if (this.currentSlide < 3) {
        this.currentSlide++;
      } else {
        this.currentSlide = 1;
      }
    },
    slideDecr() {
      if (this.currentSlide > 1) {
        this.currentSlide--;
      } else {
        this.currentSlide = 3;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.content {
  position: relative;
}
@media (min-width: 1200px) {
  .content {
    padding: 180px 0;
  }
}
.drone-bubbles-mobile {
  display: none;
  img {
    width: 200%;
  }
}

@media (max-width: 1200px) {
  .drone-bubbles-mobile {
    display: block;
    position: absolute;
    opacity: 0.5;
    top: 20%;
  }
}

.steps-slider {
  display: none;
  margin: 40px 0;
  position: relative;
  z-index: 999 !important;
  .slider-control {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin: 40px 0;
    .control-arrow {
      border: 1px solid black;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 10px 14px;
    }
    .slider-dots {
      width: 20%;
      display: flex;
      justify-content: space-between;
      i {
        color: #e2e4e5;
        font-size: 12px;
        margin: 0 2px;
      }
      .active {
        color: #000 !important;
      }
    }
  }
  .step-slide {
    display: flex;
    justify-content: center;
    align-items: center;
    .slide-number {
      width: 100%;
      display: flex;
      justify-content: center;
      .three-slide-img {
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #b9a3c9;
        padding: 10px 20px;
      }
    }
    .slide-text {
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 22px;
      span {
        font-weight: bold;
      }
    }
  }
}

.step {
  .text {
    width: 80%;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    span {
      font-weight: bold;
    }
  }
  img {
    margin: 0 0 30px 0;
  }
}

.three-container {
  background: #b9a3c9;
  margin: -60px 0 10px 0;
  border-radius: 50%;
  width: 180px;
  height: 180px;
  display: flex;
  justify-content: center;
  padding: 60px 0 0;
  img {
    margin: 0;
    width: 84px;
    height: 103px;
  }
}

.result {
  .result-title {
    padding: 0 0 40px 0;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
  }
  .result-description {
    ul {
      padding: 0;
      li {
        list-style-position: outside;
        padding: 0;
        &::marker {
          font-size: 20px !important;
          color: #b9a3c9;
        }
      }
    }

    .result {
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 22px;
      padding: 15px 0;
    }
    .result-footer {
      font-style: italic;
      font-weight: bold;
      font-size: 18px;
      line-height: 22px;
      padding: 15px 0;
      width: 70%;
    }
  }
}

.bubble-image {
  img {
    margin: 0 0 0 -5%;
  }
}

.code-image {
  img {
    width: 100%;
    margin: -100px 0 0 0;
  }
}

@media (max-width: 1400px) {
  .code-image {
    img {
      margin: -20px 0 0 0;
    }
  }
}

@media (max-width: 1200px) {
  .step {
    .text {
      width: 100%;
      font-size: 14px;
    }
  }
  .three-steps {
    margin: 60px 0;
  }
}

@media (max-width: 930px) {
  .step {
    .text {
      font-size: 16px;
    }
  }
}

@media (max-width: 700px) {
  .steps-slider {
    display: block;
  }
  .three-steps {
    flex-direction: column;
    justify-content: center !important;
    display: flex;
  }
  .result {
    .result-title {
      font-size: 14px;
      line-height: 22px;
    }
    .result-description {
      .result {
        font-size: 14px;
        line-height: 22px;
        padding: 5px 0;
      }
      .result-footer {
        font-size: 14px;
        line-height: 22px;
        padding: 5px 0;
        width: 100%;
      }
    }
  }
  .drone-bubbles-mobile {
    z-index: 1;
  }
}

@media (max-width: 1300px) {
  .step {
    .text {
      font-size: 16px;
    }
  }
}
</style>