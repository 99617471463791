<template>
  <div class="container-fluid with-support">
    <div class="row justify-content-center">
      <div class="col-xl-5 col-11 text">
        <img
          class="d-none d-xl-block"
          :src="require('../assets/images/support-ellipse.png')"
          alt=""
        />
        <div class="title">{{data.with_support.title}}</div>
      </div>
      <div class="col-4 bubbles text-end d-none d-xl-block">
        <img :src="require('../assets/images/support-bubble.png')" alt="" />
      </div>
    </div>
    <div class="row justify-content-center brands">
      <div class="col-xl-4 col-5 text-center">
        <img :src="require('../assets/images/leater-logo.png')" alt="" />
      </div>
      <div class="col-xl-4 col-6 text-center">
        <img :src="require('../assets/images/KUBG-logo.png')" alt="" />
      </div>
    </div>
    <div class="drone-bubbles-mobile">
      <img :src="require('../assets/images/drone-bubbles-mobile.png')" alt="" />
    </div>
  </div>
</template>

<script>
import locale from '../locale/locale.json'
export default {
  data() {
    return {
      data: locale,
    }
  }
};
</script>

<style lang="scss" scoped>
.with-support {
  position: relative;
}

.text {
  font-style: normal;
  font-weight: 800;
  font-size: 64px;
  line-height: 78px;
  display: flex;
  flex-direction: column;
  img {
    width: 178px;
    height: 178px;
    margin: 0 0 0 -200px;
  }
}

.drone-bubbles-mobile {
  display: none;
  img {
    width: 100%;
  }
}

.bubbles {
  img {
    margin: -450px -200px 0 0;
  }
}

.brands {
  padding: 150px 0 0;
}

@media (max-width: 1200px) {
  .drone-bubbles-mobile {
    display: block;
    position: absolute;
    opacity: 0.5;
    top: 80%;
    left: 20%;
  }
  .text {
    font-size: 56px;
    line-height: 58px;
    text-align: center;
  }
  .brands {
    padding: 80px 0 0;
    justify-content: space-between !important;
    img {
      padding: 30px 0;
      width: 100%;
    }
  }
}

@media (max-width: 651px) {
  .text {
    font-size: 26px;
    line-height: 38px;
  }
  .brands {
    padding: 30px 0;
    img {
      padding: 0;
      width: 100%;
    }
  }
  .drone-bubbles-mobile {
    left: 20%;
    z-index: 1;
  }
}
</style>