<template>
  <div class="container-fluid content">
    <div class="row justify-content-center image">
      <div class="col-xl-4 col-11 text text-center text-xl-start">
        <div>
          <span>STEAM-інформатика</span>
          {{data.drone.title}}
        </div>
        <img :src="require('../assets/images/copter-bubble.png')" alt="" />
      </div>
      <div class="col-xl-5"></div>
    </div>
    <div class="drone-bubbles-mobile">
      <img :src="require('../assets/images/drone-bubbles-mobile.png')" alt="" />
    </div>
  </div>
</template>

<script>
import locale from '../locale/locale.json'
export default {
  data() {
    return {
      data: locale
    }
  }
};
</script>

<style lang="scss" scoped>
.content {
  padding-top: 80px;
  background-image: url("../assets/images/drone.png");
  height: 800px;
  background-position: (50%, -150%);
  background-repeat: no-repeat;
}

.text {
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  line-height: 44px;
  color: #212121;
  padding: 100px 0;
  span {
    color: #7716c4;
  }
  img {
    padding: 80px 0 0;
  }
}

.drone-bubbles-mobile {
  display: none;
  img {
    width: 100%;
  }
}

@media (max-width: 1300px) {
  .text {
    font-size: 30px;
    line-height: 38px;
  }
}

@media (max-width: 1200px) {
  .text {
    padding: 0;
    img {
      display: none;
    }
  }

  .drone-bubbles-mobile {
    display: block;
    position: absolute;
    opacity: 0.5;
    top: 0;
    left: 20%;
  }
  .content {
    background: transparent;
    height: max-content;
    z-index: 2;
  }
}

@media (max-width: 700px) {
  .text {
    font-size: 20px;
    line-height: 24px;
  }
  .drone-bubbles-mobile {
    left: -70%;
    z-index: 1;
  }
}
</style>