<template>
  <div class="container-fluid pt-2">
    <div class="row justify-content-center">
      <div class="col-10 col-md-4 text-md-start">
        <img :src="require('../assets/images/logo.svg')" alt="" />
      </div>
      <div class="col-xl-5 col-8 d-none d-md-block text-center">
        <div class="d-flex justify-content-between">
          <div @click="scroll('goal_id')" class="nav">Про експеримент</div>
          <div @click="scroll('team')" class="nav">Експерти</div>
          <div @click="scroll('faq_teacher')" class="nav">Як долучитись</div>
          <div @click="scroll('footer')" class="nav">Контакти</div>
        </div>
      </div>
      <div class="col-2 d-md-none nav-button text-center">
        <i @click="isNav = !isNav" class="fas fa-align-justify"></i>
      </div>
    </div>
    <div v-if="isNav" class="nav-mobile">
      <div class="">
        <div @click="scroll('goal_id')" class="nav">Про експеримент</div>
        <div @click="scroll('team')" class="nav">Експерти</div>
        <div @click="scroll('faq_teacher')" class="nav">Як долучитись</div>
        <div @click="scroll('footer')" class="nav">Контакти</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isNav: false,
    };
  },
  mounted() {
    this.resizeFunc();
  },
  methods: {
    resizeFunc() {
      window.addEventListener("resize", () => {
        this.isNav = false;
      });
    },
    scroll(id) {
      /* window.scrollTo(0, int); */
      document.getElementById(id).scrollIntoView({
        behavior: "smooth"
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.container-fluid {
  z-index: 999 !important;
  position: relative;
}
.nav {
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  padding: 30px 0 10px;
  cursor: pointer;
  &:hover {
    border-bottom: 1px solid #724481;
  }
}

.nav {
  z-index: 999 !important;
}

.nav-button {
  padding: 20px 0;
  i {
    font-size: 24px;
  }
}

@media (max-width: 765px) {
  .nav {
    &:not(:first-child) {
      padding: 10px 0;
    }
  }
}
</style>