<template>
  <div id="goal_id" class="container-fluid d-flex justify-content-center content">
    <div class="row justify-content-between col-xl-9 col-12">
      <div v-html="data.our_goal.item_1" class="col-md-3 col-12 goal">
      </div>
      <div v-html="data.our_goal.item_2" class="col-md-3 col-12 goal">
      </div>
      <div v-html="data.our_goal.item_3" class="col-md-3 col-12 goal">
      </div>
    </div>
  </div>
</template>

<script>
import locale from '../locale/locale.json'
export default {
  data() {
    return {
      data: locale
    }
  }
};
</script>

<style lang="scss" scoped>
.content {
  padding: 80px 0 0;
}

.goal {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  margin: 0 0 20px;
  span {
    font-weight: bold;
  }
}

@media (max-width: 768px) {
  .goal {
    font-size: 14px;
    line-height: 18px;
  }
}
</style>