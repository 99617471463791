<template>
  <Wrapper />
  <Drone />
  <Goal />
  <Support />
  <Steps />
  <Partners />
  <Procces />
  <Info />
  <Team />
  <Footer />
  <div @click="scrollUp" class="nav-arrow">
    <i v-show="arrowShow == 1" class="fas fa-angle-double-up"></i>
  </div>
</template>

<script>
import Wrapper from "@/components/wrapper.vue";
import Drone from "@/components/drone.vue";
import Goal from "@/components/our-goal.vue";
import Support from "@/components/with-support.vue";
import Steps from "@/components/3-steps.vue";
import Partners from "@/components/who-joined.vue";
import Procces from "@/components/procces.vue";
import Info from "@/components/info.vue";
import Team from "@/components/team.vue";
import Footer from "@/components/footer-component.vue";

export default {
  name: "App",
  components: {
    Wrapper,
    Drone,
    Goal,
    Support,
    Steps,
    Partners,
    Procces,
    Info,
    Team,
    Footer,
  },
  data() {
    return {
      arrowShow: false,
    };
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll() {
      let offset = document.documentElement.scrollTop;
      if (offset > 4000) {
        this.arrowShow = true;
      } else {
        this.arrowShow = false;
      }
    },
    scrollUp() {
      document.documentElement.scrollTop = 0
    }
  },
};
</script>

<style lang="scss">
html {
  overflow-x: hidden;
}
body {
  width: 100%;
  font-family: "Montserrat", sans-serif !important;
  overflow-x: hidden;
  background: url("./assets/images/bg-1600.svg") no-repeat;
}

#app {
  min-width: 375px;
  overflow-x: hidden;
  position: relative;
}

.nav-arrow {
  width: 50px;
  position: fixed !important;
  top: 40%;
  right: 30px;
  font-size: 40px !important;
  text-align: center;
  cursor: pointer;
}
</style>
