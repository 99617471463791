<template>
  <div id="team" class="container-fluid content">
    <div class="row justify-content-center">
      <div class="col-11 col-xl-9">
        <div class="title">{{ data.team.title }}</div>
      </div>
      <div class="col-11 col-xl-9 team">
        <div class="row">
          <div v-for="item in team" :key="item.idx" class="col-3">
            <div class="teammate-card">
              <img :src="require(`../assets/images/${item.img}`)" alt="" />
              <div class="teammate-info">
                <div class="teammate-name">{{ item.name }}</div>
                <div class="teammate-desc">
                  {{ item.desc }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="steps-slider">
        <div
          v-for="item in team"
          :key="item.idx"
          v-show="currentSlide === item.idx"
          class="step-slide"
        >
          <div class="slide-number">
            <img :src="require(`../assets/images/${item.img}`)" alt="" />
          </div>
          <div class="slide-text-title">{{ item.name }}</div>
          <div class="slide-text">
            {{ item.desc }}
          </div>
        </div>
        <div class="slider-control">
          <div @click="slideDecr" class="control-arrow">
            <i class="fas fa-chevron-left"></i>
          </div>
          <div class="slider-dots">
            <i
              v-for="item in team"
              :key="item.idx"
              :class="{ active: currentSlide === item.idx }"
              class="fas fa-circle"
            ></i>
          </div>
          <div @click="slideIncr" class="control-arrow">
            <i class="fas fa-chevron-right"></i>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import locale from "../locale/locale.json";
export default {
  data() {
    return {
      data: locale,
      currentSlide: 1,
      team: [
        {
          idx: 1,
          name: locale.team.items.item1.name,
          desc: locale.team.items.item1.desc,
          img: locale.team.items.item1.img,
        },
        {
          idx: 2,
          name: locale.team.items.item2.name,
          desc: locale.team.items.item2.desc,
          img: locale.team.items.item2.img,
        },
        {
          idx: 3,
          name: locale.team.items.item3.name,
          desc: locale.team.items.item3.desc,
          img: locale.team.items.item3.img,
        },
        {
          idx: 4,
          name: locale.team.items.item4.name,
          desc: locale.team.items.item4.desc,
          img: locale.team.items.item4.img,
        },
        {
          idx: 5,
          name: locale.team.items.item5.name,
          desc: locale.team.items.item5.desc,
          img: locale.team.items.item5.img,
        },
      ],
    };
  },
  methods: {
    slideIncr() {
      if (this.currentSlide < this.team.length) {
        this.currentSlide++;
      } else {
        this.currentSlide = 1;
      }
    },
    slideDecr() {
      if (this.currentSlide > 1) {
        this.currentSlide--;
      } else {
        this.currentSlide = this.team.length;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@media (min-width: 1200px) {
  .content {
    padding: 180px 0 0;
  }
}

.title {
  width: 70%;
  font-style: normal;
  font-weight: 800;
  font-size: 64px;
  line-height: 78px;
}

.team {
  padding: 100px 0;

  .row {
    .col-3 {
      &:nth-child(1) {
        transform: translate(0, 30%);
      }
      &:nth-child(2) {
        transform: translate(0, -20%);
      }
      &:nth-child(3) {
        transform: translate(0, 28%);
      }
      &:nth-child(5) {
        transform: translate(100%);
      }
    }
  }
}

.teammate-card {
  text-align: center;
  img {
    width: 100%;
    border-radius: 50%;
    border: 1px solid #000;
  }
  .teammate-info {
    text-align: center;
    padding: 20px 0;
    .teammate-name {
      padding: 0 0 20px 0;
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 22px;
    }
    .teammate-desc {
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 22px;
    }
  }
}
.steps-slider {
  display: none;
  margin: 0 0 50px;
  .slider-control {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    .control-arrow {
      border: 1px solid black;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 10px 14px;
    }
    .slider-dots {
      width: 20%;
      display: flex;
      justify-content: space-between;
      i {
        color: #e2e4e5;
        font-size: 12px;
        margin: 0 2px;
      }
      .active {
        color: #000 !important;
      }
    }
  }
  .step-slide {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 309px;
    .slide-number {
      display: flex;
      justify-content: center;
      width: 50%;
      img {
        width: 100%;
        border-radius: 50%;
        border: 1px solid #000;
      }
      .three-slide-img {
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #b9a3c9;
        padding: 10px 20px;
      }
    }
    .slide-text-title {
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 18px;
      padding: 10px 0 0;
      text-align: center;
    }
    .slide-text {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
      padding: 10px 0 0;
      text-align: center;
    }
  }
}

@media (max-width: 1200px) {
  .title {
    width: 100%;
    font-size: 56px;
    line-height: 68px;
    text-align: center;
  }
}

@media (max-width: 700px) {
  .title {
    font-size: 26px;
    line-height: 28px;
  }
}

@media (max-width: 900px) {
  .team {
    display: none;
  }
  .steps-slider {
    display: block;
  }
}
</style>