<template>
  <div class="container-fluid content">
    <div class="row justify-content-center teacher-info">
      <div class="col-11 col-xl-9">
        <div id="faq_teacher" class="title">
          Важлива інформація для вчителів :
        </div>
      </div>
      <div class="features-container">
        <div
          @click="showDesc(item.idx)"
          v-for="item in teacher_info"
          :key="item.idx"
          class="feature"
        >
          <div class="feature-title">
            <div class="title-text">
              {{ item.title }}
            </div>
            <img
              v-show="current_item === item.idx"
              :src="require('../assets/images/arrow-up.png')"
              alt=""
            />
            <img
              v-show="current_item !== item.idx"
              :src="require('../assets/images/arrow-down.png')"
              alt=""
            />
          </div>
          <div v-html="item.desc" v-show="current_item === item.idx" class="feature-description">
          </div>
        </div>
      </div>
    </div>
    <div class="row justify-content-center parent-info">
      <div class="col-11 col-xl-9">
        <div class="title">Важлива інформація для батьків:</div>
      </div>
      <div class="features-container">
        <div
          @click="showDesc_2(item.idx)"
          v-for="item in parent_info"
          :key="item.idx"
          class="feature"
        >
          <div class="feature-title">
            <div class="title-text">
              {{ item.title }}
            </div>
            <img
              v-show="current_item_2 === item.idx"
              :src="require('../assets/images/arrow-up.png')"
              alt=""
            />
            <img
              v-show="current_item_2 !== item.idx"
              :src="require('../assets/images/arrow-down.png')"
              alt=""
            />
          </div>
          <div v-show="current_item_2 === item.idx" class="feature-description">
            {{ item.desc }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import locale from "../locale/locale.json";
export default {
  data() {
    return {
      current_item: 0,
      current_item_2: 0,
      teacher_info: [
        {
          idx: 1,
          title: locale.info.teacher.item1.title,
          desc: locale.info.teacher.item1.description,
        },
        {
          idx: 2,
          title: locale.info.teacher.item2.title,
          desc: locale.info.teacher.item2.description,
        },
        {
          idx: 3,
          title: locale.info.teacher.item3.title,
          desc: locale.info.teacher.item3.description,
        },
        {
          idx: 4,
          title: locale.info.teacher.item4.title,
          desc: locale.info.teacher.item4.description,
        },
        {
          idx: 5,
          title: locale.info.teacher.item5.title,
          desc: locale.info.teacher.item5.description,
        },
      ],
      parent_info: [
        {
          idx: 1,
          title: locale.info.parent.item1.title,
          desc: locale.info.parent.item1.description,
        },
        {
          idx: 2,
          title: locale.info.parent.item2.title,
          desc: locale.info.parent.item2.description,
        },
        {
          idx: 3,
          title: locale.info.parent.item3.title,
          desc: locale.info.parent.item3.description,
        },
        {
          idx: 4,
          title: locale.info.parent.item4.title,
          desc: locale.info.parent.item4.description,
        },
/*         {
          idx: 5,
          title: locale.info.parent.item5.title,
          desc: locale.info.parent.item5.description,
        }, */
      ],
    };
  },
  methods: {
    showDesc(idx) {
      if (this.current_item != idx) {
        this.current_item = idx;
      } else {
        this.current_item = 0;
      }
    },
    showDesc_2(idx) {
      if (this.current_item_2 != idx) {
        this.current_item_2 = idx;
      } else {
        this.current_item_2 = 0;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.title {
  width: 70%;
  font-style: normal;
  font-weight: 800;
  font-size: 64px;
  line-height: 78px;
}

.parent-info {
  margin: 100px 0;
}

.features-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 50px 0;
  .feature {
    width: 625px;
    padding: 30px;
    background: #ffffff;
    border: 2px solid #602e70;
    box-sizing: border-box;
    border-radius: 20px;
    margin: 5px 0;
    cursor: pointer;
    .feature-title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .title-text {
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 22px;
        color: #724481;
      }
    }
    .feature-description {
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 22px;
      padding: 20px 0 0;
    }
  }
}

@media (max-width: 1200px) {
  .title {
    width: 100%;
    font-style: normal;
    font-weight: 800;
    font-size: 56px;
    line-height: 68px;
    text-align: center;
  }
  .parent-info {
    margin: 0;
  }
  .teacher-info {
    margin: 80px 0 0;
  }
}

@media (max-width: 700px) {
  .title {
    width: 100%;
    font-style: normal;
    font-weight: 800;
    font-size: 26px;
    line-height: 28px;
  }
  .feature {
    width: 95% !important;
    padding: 20px;
    background: #ffffff;
    border: 2px solid #602e70;
    box-sizing: border-box;
    border-radius: 20px;
    margin: 5px 0;
    .title-text {
      font-size: 14px !important;
      line-height: 18px !important;
    }
    .feature-description {
      font-size: 14px !important;
      line-height: 18px !important;
    }
  }
}
</style>